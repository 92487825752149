import React, { useState } from 'react';

import Building from '@/components/commons/icons/Building';
import CalendarMonth from '@/components/commons/icons/CalendarMonth';

import { getYear } from './helpers';

function Career({ brief = null }) {
    const [showAllCareerExperiences, setShowAllCareerExperiences] = useState(false);

    if (!brief) {
        return null;
    }

    const viewMoreLessButton = () => (
        <button
            type="button"
            key="view-more-less"
            className="text-progressBarBlue font-medium mt-2"
            onClick={() => setShowAllCareerExperiences(!showAllCareerExperiences)}
        >
            {!showAllCareerExperiences ? 'View more' : 'View Less'}
        </button>
    );

    return (
        <div className="bg-cirrusWhite rounded-lg p-6 mb-6 border-shadeStoneGray border-[1px]">
            <p className="font-bold mb-6">Career</p>
            {brief.workHistoryItems && brief.workHistoryItems.map((el, i) => {
                if (i > 3 && !showAllCareerExperiences) {
                    return null;
                }
                if (i === 3 && !showAllCareerExperiences) {
                    return viewMoreLessButton();
                }
                return (
                    <div key={`work-history=${el.id}`} className="">
                        <div className="flex flex-col shadow-sm border border-gray-100 bg-gray-50 rounded-lg mb-4">
                            <div className="flex flex-col md:flex-row justify-center md:justify-start items-center px-1 md:px-8 py-2 ">
                                <p className="mb-2 md:mb-0 mt-2 md:mt-0 h-[40px] md:h-auto min-w-auto max-w-auto md:max-w-[50%] md:min-w-[50%] font-bold text-center md:text-start">{el.title}</p>
                                <div className="md:mb-0 h-[60px] md:h-auto flex flex-col md:flex-row justify-start  items-center min-w-auto max-w-auto md:max-w-[25%] md:min-w-[25%]">
                                    <Building className="w-4 mb-2 md:mb-0 mr-0 md:mr-2 text-cleanSlate" />
                                    <p className="text-center md:text-start">{el.organization}</p>
                                </div>
                                <div className="md:mb-0  flex flex-col md:flex-row justify-end items-center min-w-auto max-w-auto md:max-w-[25%] md:min-w-[25%]">
                                    <CalendarMonth className="w-4 mr-0 md:mr-2 text-cleanSlate" />
                                    <p className="text-center md:text-start">{`${getYear(el.startAt)} - ${getYear(el.endAt)}`}</p>
                                </div>
                            </div>
                            <div className="px-1 md:px-8 pb-4">
                                <p className="text-center md:text-left mb-2 md:mb-0 mt-2 md:mt-0 h-auto ">{el.description}</p>
                            </div>
                        </div>
                        {(
                            i === brief.workHistoryItems.length - 1
                            && brief.workHistoryItems.length > 3
                        ) && viewMoreLessButton()}
                    </div>
                );
            })}
        </div>
    );
}

export default Career;
