import React, { useRef } from 'react';
import { Field } from 'formik';

import Description from '@/components/forms/description';

import styles from './ToggleButton.module.css';

interface Props {
    readonly className?: string;
    readonly checked: boolean;
    readonly description?: string;
    readonly error?: boolean;
    readonly errorText?: string;
    readonly label?: string;
    readonly name: string;
    readonly onChange?: () => void;
    readonly disabled?: boolean;
    readonly 'data-tooltip-id'?: string;
    readonly 'data-tooltip-content'?: string;
}

function ToggleButton({
    className = '',
    checked,
    description = '',
    error = false,
    errorText = '',
    label = '',
    name,
    onChange = () => { },
    disabled = false,
    ...props
}: Props) {
    const dataTooltipId = props['data-tooltip-id'];
    const dataTooltipContent = props['data-tooltip-content'];
    const inputEl = useRef(null);

    const onButtonClick = () => {
        inputEl.current.click();
    };

    return (
        <div className={className}>
            <label
                htmlFor={name}
                className={styles.inputContainer}
            >
                <Field
                    name={name}
                >
                    {({ field, form: { handleChange } }) => (
                        <div
                            className={styles.inputWrapper}
                        >
                            <input
                                type="checkbox"
                                id={name}
                                className="hidden"
                                ref={inputEl}
                                name={name}
                                {...field}
                                checked={checked}
                                value={undefined}
                                disabled={disabled}
                                onChange={(e) => {
                                    handleChange(name)(e);
                                    onChange();
                                }}
                            />
                            <div
                                className={styles.area}
                                onKeyDown={(event) => {
                                    if (event.key === ' ' || event.key === 'Enter') {
                                        if (!disabled) {
                                            onButtonClick();
                                        }
                                        event.preventDefault();
                                    }
                                }}
                                role="checkbox"
                                aria-checked={field.value}
                                aria-label={name}
                                data-tooltip-id={dataTooltipId}
                                data-tooltip-content={dataTooltipContent}
                                tabIndex={0}
                            />
                            <div className={styles.dot} />
                        </div>
                    )}
                </Field>
                <div className={`${styles.label} ${error && styles.labelError}`}>
                    {label}
                </div>
            </label>

            <Description description={description} externalStyles="" />
            {error && errorText.length > 0 && (
                <div className={styles.errorText}>
                    {errorText}
                </div>
            )}
        </div>
    );
}

export default ToggleButton;
