import React, { ChangeEventHandler } from 'react';
import { TextInput } from 'flowbite-react';

interface Props {
  readonly field: { // { name, value, onChange, onBlur }
    name: string;
    value: string;
    onChange: ChangeEventHandler;
  };
}

function Input({ field, ...props }: Props) {
    return <TextInput {...field} {...props} />;
}

export default Input;
