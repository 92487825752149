import React from 'react';
import { Field } from 'formik';

import TextAreaInput from '@/components/bio/TextArea';
import FormError from '@/components/forms/FormError';

import { bioPlaceholder } from '../mockedData';

function BioEditor({
    brief = null, setStatus, status, handleChange,
}) {
    if (!brief) {
        return null;
    }

    return (
        <div>
            <div className="bg-cirrusWhite rounded-lg p-6 mb-6 shadow-sm">
                <p className="font-bold mb-6">Biography</p>
                <Field
                    id="biography"
                    name="biography"
                    placeholder={bioPlaceholder}
                    component={TextAreaInput}
                    onChange={(e) => {
                        if (status?.errors?.biography) {
                            setStatus({ errors: {} });
                        }
                        handleChange(e);
                    }}
                    className="resize-none min-h-[508px] text-cleanSlate font-instrument text-base font-normal leading-7 px-4 py-3 w-full"
                />
                <FormError
                    error={status?.errors?.biography as string[]}
                />
            </div>
        </div>
    );
}

export default BioEditor;
