import React from 'react';
import { Textarea } from 'flowbite-react';
import customTextAreaTheme from './TextAreaTheme';

interface Props {
  readonly field: { // { name, value, onChange, onBlur }
    name: string;
    value: string;
  };
}

function TextAreaInput({ field, ...props }: Props) {
    return <Textarea {...field} {...props} theme={customTextAreaTheme} />;
}

export default TextAreaInput;
