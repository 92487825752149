import React from 'react';
import { Form } from 'formik';

import ToggleButton from '@/components/forms/toggle_button';

interface Props {
    readonly fieldName: string;
    readonly propertyName: string;
    readonly record: any;
    readonly name?: string;
    readonly onChange: (id: any, primary: any, record: any) => Promise<void>;
    readonly disabled?: boolean;
    readonly 'data-tooltip-id'?: string;
    readonly 'data-tooltip-content'?: string;
}

function ToggleForm({
    record,
    onChange,
    fieldName,
    propertyName,
    disabled = false,
    ...props
}: Props) {
    return (
        <Form>
            <ToggleButton
                onChange={() => onChange(
                    record.id,
                    !record[propertyName],
                    record || null,
                )}
                checked={!!record[propertyName]}
                disabled={!!disabled}
                name={fieldName}
                data-tooltip-id={props['data-tooltip-id']}
                data-tooltip-content={props['data-tooltip-content']}
            />
        </Form>
    );
}

export default ToggleForm;
