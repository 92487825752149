const socialsUrlCleaner = (values) => {
    const output = { ...values };

    const domains = {
        linkedIn: 'https://www.linkedin.com/in/',
        xTwitter: 'https://www.x.com/',
        instagram: 'https://www.instagram.com/',
        facebook: 'https://www.facebook.com/',
        gitHub: 'https://www.github.com/',
    };

    Object.keys(output).forEach((value) => {
        if (output[value] !== '' && domains[value]) {
            let userCleaned;
            if (!/\.com/i.test(output[value])) {
                userCleaned = output[value].indexOf('/') !== -1
                    ? output[value].slice(output[value].indexOf('/') + 1)
                    : output[value];
                output[value] = domains[value] + userCleaned;
            } else if (!/^https?:\/\//i.test(output[value]) && /\.com/i.test(output[value])) {
                userCleaned = output[value].indexOf('.com/') !== -1
                    ? output[value].slice(output[value].indexOf('.com/') + 5)
                    : output[value];
                output[value] = domains[value] + userCleaned;
            }
        } else if (output[value] && value.includes('other')) {
            if (!/^https?:\/\//i.test(output[value])) {
                output[value] = `https://${output[value]}`;
            }
        }
    });

    return output;
};

export default socialsUrlCleaner;
