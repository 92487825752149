import React, { useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

import { handleValidationErrors, onCompletedHandler, onErrorHandler } from '@/services/apollo';
import { UPDATE_BRIEF } from '@/components/onboarding/Actions';
import { haveErrors, reportGeneralError } from '@/components/onboarding/Shared';
import WarningCard from '@/components/commons/warning_card';
import socialsUrlCleaner from '@/components/bio/helpers';

import BioEditor from '../editors/BioEditor';
import PreferencesEditor from '../editors/PreferencesEditor';
import CareerEditor from '../editors/CareerEditor';
import SocialLinksEditor from '../editors/SocialLinksEditor';
import { checkWorkHistoryMissingFields, formatWorkHistoryItems } from '../helpers';
import BriefHeader from './BriefHeader';

function BriefEditor({
    brief, user, setEditing, refetch,
}) {
    const [formError, setFormError] = useState('');
    const [workHistory, setWorkHistory] = useState(brief.workHistoryItems);
    const [birthday, setBirthday] = useState(brief.birthday);

    const [updateBrief] = useMutation(UPDATE_BRIEF);

    const formRef = useRef<FormikProps<any>>(null);

    const handleContinue = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleBriefUpdate = async (values, setSubmitting, setStatus) => {
        const sanitizedUrls = socialsUrlCleaner(values.socialLinks);

        const attributes = {
            biography: values.biography,
            meetingPreferences: values.meetingPreferences || null,
            communicationPreferences: values.communicationPreferences || null,
            name: values?.canonicalKey?.toLowerCase(),
            displayKey: values?.canonicalKey?.toLowerCase(),
            workHistoryItems: formatWorkHistoryItems(workHistory),
            location: values.location,
            birthday,
            socialLinks: {
                ...user?.socialLinks,
                linkedIn: sanitizedUrls.linkedIn || null,
                xTwitter: sanitizedUrls.xTwitter || null,
                facebook: sanitizedUrls.facebook || null,
                instagram: sanitizedUrls.instagram || null,
                gitHub: sanitizedUrls.gitHub || null,
                other_1: sanitizedUrls.other_1 || null,
                other_2: sanitizedUrls.other_2 || null,
            },
        };

        const response = await updateBrief({
            variables: {
                input: {
                    id: brief.id,
                    attributes,
                },
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
            onError: onErrorHandler((error) => {
                setSubmitting(false);
                reportGeneralError(error);
            }),
            onCompleted: onCompletedHandler(() => { }, (_) => {
                setSubmitting(false);
            }),
        });

        const errors = response.data?.updateBrief?.errors;

        if (haveErrors(errors)) {
            handleValidationErrors(errors, setStatus, 'brief');
            if (errors[0].message === 'has already been taken') {
                setFormError('That Sixty brief ID is not available');
                return;
            }
            setFormError(errors[0].message);
            return;
        }

        setStatus({ errors: {} });
        toast.success('Changes saved succesfully!');
        refetch();
        setEditing(false);
    };

    return (
        <div>
            {
                formError !== '' && (
                    <div className="w-full my-[20px]">
                        <WarningCard message={formError} fontColor="cirrusWhite" />
                    </div>
                )
            }
            <Formik
                innerRef={formRef}
                onSubmit={async (frm, { setStatus, setSubmitting }) => {
                    const errorsInWorkHistory = checkWorkHistoryMissingFields(
                        workHistory,
                    ).indexOf(true);
                    if (!frm.biography) {
                        setStatus({ errors: { biography: ['Please enter a biography.'] } });
                        setFormError('Please Check your fields');
                        setSubmitting(false);
                        return;
                    }
                    if (errorsInWorkHistory !== -1) {
                        const workHistoryErrors = Array.from(
                            { length: workHistory.length },
                            () => [],
                        );
                        workHistoryErrors[errorsInWorkHistory] = ['This field can\'t be blank.'];
                        setStatus({ errors: { workHistoryErrors } });
                        setFormError('Please Check your fields');
                        setSubmitting(false);
                        return;
                    }

                    const values = {
                        biography: frm.biography,
                        meetingPreferences: frm.meetingPreferences,
                        communicationPreferences: frm.communicationPreferences,
                        location: frm.location,
                        birthday: frm.birthday,
                        workHistoryItems: frm.workHistoryItems,
                        name: frm.name,
                        canonicalKey: frm.canonicalKey,
                        socialLinks: {
                            linkedIn: frm?.linkedIn,
                            xTwitter: frm?.xTwitter,
                            facebook: frm?.facebook,
                            gitHub: frm?.gitHub,
                            instagram: frm?.instagram,
                            other_1: frm?.other_1,
                            other_2: frm?.other_2,
                        },
                    };

                    await handleBriefUpdate(
                        values,
                        setSubmitting,
                        setStatus,
                    );
                }}
                initialValues={{
                    biography: brief.biography || '',
                    meetingPreferences: brief.meetingPreferences || '',
                    communicationPreferences: brief.communicationPreferences || '',
                    location: user.location || brief.location || '',
                    birthday: user.birthday || brief.birthday || '',
                    name: brief.name || '',
                    canonicalKey: brief.displayKey || '',
                    linkedIn: brief?.socialLinks?.linkedIn || user?.socialLinks?.linkedIn || '',
                    xTwitter: brief?.socialLinks?.xTwitter || user?.socialLinks?.xTwitter || '',
                    facebook: brief?.socialLinks?.facebook || user?.socialLinks?.facebook || '',
                    gitHub: brief?.socialLinks?.gitHub || user?.socialLinks?.gitHub || '',
                    instagram: brief?.socialLinks?.instagram || user?.socialLinks?.instagram || '',
                    other_1: brief?.socialLinks?.other_1 || user?.socialLinks?.other_1 || '',
                    other_2: brief?.socialLinks?.other_2 || user?.socialLinks?.other_2 || '',
                    workHistoryItems: brief?.workHistoryItems,
                }}
            >
                {({ status, setStatus, handleChange }) => (
                    <Form>
                        <div className="m-4 md:m-0 rounded-lg mb-10 bg-transparent">
                            <BriefHeader
                                brief={brief}
                                user={user}
                                editing
                                setEditing={setEditing}
                                submit={handleContinue}
                            />
                            <div className="w-full flex flex-col md:flex-row ml-1 md:ml-0 mb-6">
                                <div className="w-full">
                                    <BioEditor
                                        brief={brief}
                                        setStatus={setStatus}
                                        status={status}
                                        handleChange={handleChange}
                                    />
                                    <CareerEditor
                                        brief={brief}
                                        status={status}
                                        setWorkHistory={setWorkHistory}
                                    />
                                    <PreferencesEditor
                                        brief={brief}
                                        status={status}
                                    />
                                </div>
                                <SocialLinksEditor
                                    brief={brief}
                                    status={status}
                                    birthday={birthday}
                                    setBirthday={setBirthday}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default BriefEditor;
