import React from 'react';

interface Props {
  readonly error?: string | string[] | { [key: string]: string };
  readonly externalStyles?: string
}

export default function FormError({
    error = null,
    externalStyles = '',
}: Props) {
    let message = null;
    const items = [];

    // no error, or error is an empty array or object
    if (!error || (typeof error === 'object' && Object.keys(error).length === 0)) {
        return null;
    }
    if (typeof error === 'object') {
        // length implies an array (we check if length is 0 above and return null)
        if (error.length) {
            message = (error as string[]).join(', ');
        } else {
            // no length means error is an object
            // pull the keys and errors out into react component array to render
            Object.keys(error).forEach((key) => {
                const msg = error[key];
                items.push(
                    <p key={key}>
                        {key}
                        :
                        {' '}
                        {msg}
                    </p>,
                );
            });
        }
    } else {
        message = error as string;
    }

    return (
        <div id="form-error" className={`bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm ${externalStyles}`}>
            {message || items}
        </div>
    );
}
