import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
// import en from 'date-fns/locale/en-US';
import FormError from '@/components/forms/FormError';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import CalendarOutline from '@/components/commons/icons/CalendarOutline';

import { getYear, handleRangeToYears } from '../helpers';

export default function DatePicker({
    index, updateWorkHistoryField, updatedWorkHistory, status,
}) {
    const [showRange, setShowRange] = useState(false);

    const handleChange = (target) => {
        handleRangeToYears(target, index, updateWorkHistoryField);
    };

    const startDate = updatedWorkHistory.startAt ? new Date(updatedWorkHistory.startAt) : '-';
    const endDate = updatedWorkHistory.endAt ? new Date(updatedWorkHistory.endAt) : null;

    const selectionRange = {
        startDate: updatedWorkHistory.startAt
            ? new Date(updatedWorkHistory.startAt)
            : new Date(Date.now()),
        endDate: updatedWorkHistory.endAt
            ? new Date(updatedWorkHistory.endAt)
            : null,
        key: 'period',
    };

    const handleOpenRange = (e) => {
        e.preventDefault();
        setShowRange(!showRange);
    };

    const getShownDate = () => {
        if (endDate || (!endDate && startDate !== '-')) {
            return `${getYear(startDate)}-${getYear(endDate)}`;
        }
        return 'Period Not Selected';
    };

    return (
        <div className="mb-2 md:mb-0 flex flex-col justify-start items-start px-8">
            <p className="mb-4 mt-2 md:mt-0 font-bold text-center md:text-start">When did you work here?</p>
            <div className="pb-4 w-full">
                <div className="flex flex-col items-center sm:flex-row">
                    <div className="relative flex flex-col justify-center items-center md:items-start pr-0 sm:pr-2 w-full md:w-1/2">
                        <button
                            type="button"
                            data-tooltip-id="tip"
                            title="Period"
                            aria-label="Period"
                            onClick={(e) => handleOpenRange(e)}
                            className="flex items-center w-full border-[1px] border-gray-300 rounded-lg py-2"
                        >
                            <CalendarOutline className="w-4 ml-3 mr-2 text-cleanSlate" />
                            <p className="text-[14px] py-1">{getShownDate()}</p>
                        </button>
                        {showRange && (
                            <DateRange
                                ranges={[selectionRange]}
                                onChange={(e) => handleChange(e)}
                                // locale={en}
                                dateDisplayFormat="yyyy"
                                maxDate={new Date(Date.now())}
                                minDate={new Date('1950-01-01')}
                                rangeColors={['#1091AE']}
                                className="absolute top-[50px]"
                                // retainEndDateOnFirstSelection
                                endDatePlaceholder="present"
                            />
                        )}
                        <FormError
                            error={!updatedWorkHistory.startAt ? status?.errors?.workHistoryErrors[index] as string[] : ''}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
