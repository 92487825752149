import React, { useState } from 'react';
import { Field } from 'formik';

import MapPin from '@/components/commons/icons/MapPin';
import ProfileCardSolid from '@/components/commons/icons/ProfileCardSolid';
import Briefcase from '@/components/commons/icons/Briefcase';
import StarSolid from '@/components/commons/icons/Star';
import Instagram from '@/components/commons/icons/Instagram';
import XTwitter from '@/components/commons/icons/XTwitter';
import Facebook from '@/components/commons/icons/Facebook';
import LinkedIn from '@/components/commons/icons/LinkedIn';
import LinkIcon from '@/components/commons/icons/LinkIcon';
import GitHub from '@/components/commons/icons/GitHub';
import FormError from '@/components/forms/FormError';

import Input from '@/components/bio/Input';
import DatePicker from '@/components/bio/DatePicker';

import { getFullBirthday } from '../helpers';

function SocialLinksEditor({
    brief = null, status, birthday, setBirthday,
}) {
    const [showBirthdayPicker, setShowBirthdayPicker] = useState(false);

    if (!brief) {
        return null;
    }

    const placeholder = {
        linkedIn: 'https://www.linkedin.com/in/your-user',
        xTwitter: 'https://www.x.com/your-user',
        instagram: 'https://www.instagram.com/your-user',
        facebook: 'https://www.facebook.com/your-user',
        gitHub: 'https://www.github.com/your-user',
        other_1: 'https://your-website.com/',
        other_2: 'https://your-other-website.com/',
    };

    const icons = {
        linkedIn: LinkedIn,
        xTwitter: XTwitter,
        instagram: Instagram,
        facebook: Facebook,
        gitHub: GitHub,
        other_1: LinkIcon,
        other_2: LinkIcon,
    };

    const options = {
        datepickerClassNames: 'relative sm:absolute top-[0px] sm:top-[120px] left-auto translate-x-0 ml-[-36px] sm:ml-0',
        maxDate: new Date(Date.now()),
        minDate: new Date('1920-01-01'),
        inputNameProp: 'birthday',
        inputIdProp: 'birthday',
        theme: {
            selected: 'bg-cleanSlate',
            hover: '!text-cleanSlate',
            todayBtn: 'bg-cleanSlate',
            text: 'text-instrument text-cleanSlate',
            input: 'text-instrument text-cleanSlate',
        },
        defaultDate: null,
    };

    return (
        <div className="flex flex-col min-w-72 ml-4 md:ml-10">
            <div className="flex mb-3 pr-6 md:pr-0">
                <MapPin className="w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col w-full">
                    <p className="font-bold">Location</p>
                    <Field
                        id="location"
                        name="location"
                        component={Input}
                        placeholder="Enter your general location"
                        className="resize-none text-cleanSlate font-instrument text-base font-normal leading-7 pt-2 w-full"
                    />
                    <FormError
                        error={status?.errors?.location as string[]}
                    />

                </div>
            </div>
            <div className="flex mb-3 pr-6 md:pr-0">
                <StarSolid className="w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col w-full">
                    <p className="font-bold mb-2">Birthday</p>
                    <Field
                        name="contactBirthday"
                        component={DatePicker}
                        show={showBirthdayPicker}
                        setShow={setShowBirthdayPicker}
                        options={{
                            ...options,
                            defaultDate: getFullBirthday(birthday)
                                ? new Date(getFullBirthday(birthday))
                                : null,
                        }}
                        onChange={setBirthday}
                        value={getFullBirthday(birthday)}
                    />
                </div>
            </div>
            <div className="flex mb-3 pr-6 md:pr-0">
                <ProfileCardSolid className="w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col w-full">
                    <p className="font-bold mb-2">Sixty brief</p>
                    <Field
                        id="canonicalKey"
                        name="canonicalKey"
                        component={Input}
                        placeholder="you-sixty-brief-link"
                    />
                    <FormError
                        error={status?.errors?.canonicalKey as string[]}
                    />
                </div>
            </div>
            <div className="flex mb-3 pr-6 md:pr-0">
                <Briefcase className="min-w-4 max-w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col w-full gap-2 text-cleanSlate">
                    <p className="font-bold">Other</p>
                    {brief.socialLinks
                        && Object.keys(icons).map((element) => (
                            <div key={element}>
                                <Field
                                    id={element}
                                    name={element}
                                    component={Input}
                                    icon={icons[element]}
                                    placeholder={placeholder[element]}
                                />
                                <FormError
                                    error={status?.errors[element] as string[]}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default SocialLinksEditor;
