import React, { useState } from 'react';
import BriefHeader from './renderers/BriefHeader';
import BriefBody from './renderers/BriefBody';
import BriefEditor from './renderers/BriefEditor';

function NewBrief({ brief, user, refetch }) {
    const [editing, setEditing] = useState(false);

    return (
        <div>
            {!editing ? (
                <div className={`rounded-lg bg-lightGray mb-10 ${user?.user?.id ? 'm-4 md:m-0' : 'p-4'}`}>
                    <BriefHeader
                        brief={brief}
                        user={user}
                        editing={editing}
                        setEditing={setEditing}
                    />
                    <BriefBody
                        brief={brief}
                        user={user}
                    />
                </div>
            ) : (
                <div className="rounded-lg mb-10 bg-lightGray">
                    <BriefEditor
                        brief={brief}
                        user={user}
                        setEditing={setEditing}
                        refetch={refetch}
                    />
                </div>
            )}
        </div>
    );
}

export default NewBrief;
