import React from 'react';
import Link from 'next/link';

import MapPin from '@/components/commons/icons/MapPin';
import ProfileCardSolid from '@/components/commons/icons/ProfileCardSolid';
import Briefcase from '@/components/commons/icons/Briefcase';
import StarSolid from '@/components/commons/icons/Star';

import { formatSocialNames, getBirthday } from './helpers';

function SocialLinks({ brief = null, user }) {
    if (!brief) {
        return null;
    }

    const effectiveLocation = brief.location || user.location;
    const birthday = getBirthday(brief.birthday);

    return (
        <div className="flex flex-col min-w-[20px] md:min-w-[270px] md:max-w-[270px] ml-4 md:ml-10">
            {effectiveLocation && (
                <div className="flex mb-3">
                    <MapPin className="w-4 mr-2 mt-1 text-cleanSlate" />
                    <div className="flex flex-col">
                        <p className="font-bold">Location</p>
                        <p className="">{effectiveLocation}</p>
                    </div>
                </div>
            )}
            {birthday && (
                <div className="flex mb-3">
                    <StarSolid className="w-4 mr-2 mt-1 text-cleanSlate" />
                    <div className="flex flex-col">
                        <p className="font-bold">Birthday</p>
                        <p className="">{getBirthday(brief.birthday)}</p>
                    </div>
                </div>
            )}
            <div className="flex mb-3">
                <ProfileCardSolid className="w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col">
                    <p className="font-bold">Sixty brief</p>
                    <Link href={`/${brief.name}`} className="text-progressBarBlue">{brief.name}</Link>
                </div>
            </div>
            <div className="flex mb-3">
                <Briefcase className="min-w-4 max-w-4 mr-2 mt-1 text-cleanSlate" />
                <div className="flex flex-col">
                    <p className="font-bold">Other links</p>
                    {brief.socialLinks
                        && Object.keys(brief.socialLinks).map((element) => (
                            formatSocialNames(element, brief.socialLinks[element])
                        ))}
                </div>
            </div>
        </div>
    );
}

export default SocialLinks;
