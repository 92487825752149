import { gql } from '@apollo/client';

const FETCH_CONTACTS = gql`
query fetchContacts($visible: Boolean) {
    user {
        unifiedContacts(visible: $visible) {
            visible
            displayName
            primaryEmail
            effectiveName
            photoImageUrl
            proposedCircle
            chosenCircle
            effectiveCircle
            ignoreBirthday
            id
        }
    }
}
`;

export default FETCH_CONTACTS;
