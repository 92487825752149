import React, { useEffect, useState } from 'react';
import { wordCount } from '../helpers';
import Career from '../Career';
import SocialLinks from '../SocialLinks';

function BriefBody({ brief = null, editing = false, user }) {
    const [showFullBio, setShowFullBio] = useState(true);
    const [showFullCommPref, setShowFullCommPref] = useState(true);
    const [showFullMeetingPref, setShowFullMeetingPref] = useState(true);

    useEffect(() => {
        if (brief) {
            setShowFullBio(!(brief.biography?.split(' ').length > 140));
            setShowFullCommPref(!(brief.communicationPreferences?.split(' ').length > 70));
            setShowFullMeetingPref(!(brief.meetingPreferences?.split(' ').length > 70));
        }
    }, [brief]);

    if (!brief) {
        return null;
    }

    const textBlockRenderer = (keyBase, text, state, setState, limit) => {
        const textLength = wordCount(text, state, limit);
        const output = (
            <div className={`${editing ? 'bg-gray-50 px-4 py-3 border border-gray-300 rounded-lg' : ''}`}>
                {textLength.text?.map((e, i) => {
                    if (i === textLength.text.length - 1 && textLength.long) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`${keyBase}-expando-${i}`}>
                                <span className="mb-2">{`${e}${!state ? '... ' : ' '}`}</span>
                                {textLength.long
                                    && (
                                        <button
                                            type="button"
                                            className="text-progressBarBlue font-medium cursor-pointer"
                                            onClick={() => setState(!state)}
                                        >
                                            {!state ? 'Read more' : 'Read Less'}
                                        </button>
                                    )}
                            </div>
                        );
                    }
                    // eslint-disable-next-line react/no-array-index-key
                    return <p key={`${keyBase}-content-${i}`} className="mb-2">{`${e} `}</p>;
                })}
            </div>
        );
        return output;
    };

    return (
        <div className="w-full flex flex-col md:flex-row md:ml-0 mb-6">
            <div className="w-full">
                {brief.biography && (
                    <div className="bg-cirrusWhite rounded-lg p-6 mb-6 border-shadeStoneGray border-[1px]">
                        <p className="font-bold mb-6">Biography</p>
                        <div className="">
                            {textBlockRenderer('biography', brief.biography, showFullBio, setShowFullBio, 140)}
                        </div>
                    </div>
                )}
                <Career brief={brief} />
                {(brief.meetingPreferences || brief.communicationPreferences)
                    && (
                        <div className="bg-cirrusWhite rounded-lg p-6 mb-6 border-shadeStoneGray border-[1px]">
                            <p className="font-bold mb-6">Preferences</p>
                            {brief.meetingPreferences
                                && (
                                    <>
                                        <p className="font-bold mb-6">Meetings</p>
                                        <div className="mb-6 ">{textBlockRenderer('meetingPrefs', brief.meetingPreferences, showFullMeetingPref, setShowFullMeetingPref, 10)}</div>
                                    </>
                                )}
                            {brief.communicationPreferences
                                && (
                                    <>
                                        <p className="font-bold mb-6">Communications</p>
                                        <div className="mb-6">{textBlockRenderer('commPrefs', brief.communicationPreferences, showFullCommPref, setShowFullCommPref, 70)}</div>
                                    </>
                                )}
                        </div>
                    )}
            </div>
            <SocialLinks brief={brief} user={user} />
        </div>
    );
}

export default BriefBody;
