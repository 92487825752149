import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import DotsHorizontal from '@/components/commons/icons/DotsHorizontal';
import ProfileImage from '@/components/commons/ProfileImage';
import ChartMixedOutline from '@/components/commons/icons/ChartMixedOutline';
import CloseIcon from '@/components/commons/icons/CloseIcon';
import EyeSolid from '@/components/commons/icons/EyeSolid';
import Pencil from '@/components/commons/icons/Pencil';
import Check from '@/components/commons/icons/Check';
import TrashBin from '@/components/commons/icons/TrashBin';

import { mockedContactData } from '../mockedData';
import style from '../NewBrief.module.css';
import RelationshipInsightsTable from './RelationshipInsightsTable';

const IN_PROD = process.env.NEXT_PUBLIC_ENV === 'prod';

function BriefHeader({
    brief = null, user = null, editing = false, setEditing = null, submit = null,
}) {
    const [showInsights, setShowInsights] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const insightsVisible = (user?.signedIn && !IN_PROD) && !editing
        && user?.user?.id !== brief.userId;

    if (!brief) {
        return null;
    }

    return (
        <div className="w-full flex flex-col items-center justify-center mb-6">
            <div className={`w-full bg-cirrusWhite border-shadeStoneGray border-[1px] flex flex-col-reverse md:flex-row items-center justify-between py-2 pl-2 pr-2 md:pr-0 md:py-6 md:pl-6 ${!insightsVisible ? 'rounded-lg' : 'rounded-t-lg'} ${!user.signedIn ? style.headerMountainBackground : ''}`}>
                <div className="flex flex-col md:flex-row items-center justify-center md:justify-start w-full">
                    <div className="mr-0 my-4 md:my-0">
                        {(brief.profileImageUrl || brief.emailHash) && <ProfileImage brief={brief} height="h-[100px]" width="w-[100px]" />}
                    </div>
                    <div className="ml2 md:ml-6 flex flex-col items-center md:items-start justify-center">
                        <p className="font-bold my-1 md:my-0 text-[20px]">{brief.fullName || ''}</p>
                        <p className="text-center my-1 md:my-0 md:text-left">{`${brief.title || ''}${brief.company ? `, ${brief.company}` : ''}`}</p>
                    </div>
                </div>
                {(!IN_PROD) && (user?.user?.emailHash === brief.emailHash) && (
                    <div className="w-full flex items-start justify-start md:justify-end px-1 md:px-0 md:mr-4">
                        {!editing ? (
                            <>
                                <button
                                    type="button"
                                    data-tooltip-id="tip"
                                    title="Actions"
                                    aria-label="Actions"
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="relative border-[1px] border-cleanSlate bg-cleanSlate rounded-lg flex items-center justify-center "
                                >
                                    <div className="relative border-[1px] border-cleanSlate bg-cleanSlate rounded-lg flex items-center justify-center px-4 md:px-12 py-2">
                                        <DotsHorizontal className="w-4 mr-0 md:mr-2 text-white" />
                                        <p className="hidden md:block text-cirrusWhite">Actions</p>
                                    </div>
                                </button>
                                <Transition
                                    show={isOpen}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <button
                                        type="button"
                                        data-tooltip-id="tip"
                                        title="Edit"
                                        aria-label="Edit"
                                        onClick={() => setEditing(true)}
                                        className="rounded-lg absolute w-[180px] mt-14 md:mt-18 ml-32 md:-ml-0 bg-cleanSlate space-y-3 px-4 py-3 shadow-lg"
                                    >
                                        <div className="flex items-center pr-4 py-2">
                                            <Pencil className="w-4 mr-2 text-white" />
                                            <p className="md:block text-cirrusWhite">Edit brief</p>
                                        </div>
                                    </button>
                                </Transition>
                            </>
                        )
                            : (

                                <>
                                    <button
                                        type="button"
                                        data-tooltip-id="tip"
                                        title="Discard"
                                        aria-label="Discard"
                                        onClick={() => setEditing(false)}
                                        className="border-[1px] border-cleanSlate bg-cirrusWhite rounded-lg flex items-center justify-center px-4 md:px-3 py-2 mr-4"
                                    >
                                        <TrashBin className="w-4 h-4 mr-0 md:mr-2 text-cleanSlate" />
                                        <p className="hidden md:block font-medium">Discard Changes</p>
                                    </button>
                                    <button
                                        type="button"
                                        data-tooltip-id="tip"
                                        title="Save"
                                        aria-label="Save"
                                        onClick={() => (!editing ? setEditing(!editing) : submit())}
                                        className="border-[1px] border-cleanSlate bg-cleanSlate rounded-lg flex items-center justify-center px-4 md:px-6 py-2"
                                    >

                                        <Check className="w-4 h-4 mr-0 md:mr-2 text-white" />
                                        <p className="hidden md:block text-cirrusWhite">Save changes</p>
                                    </button>
                                </>
                            )}
                    </div>
                )}
            </div>
            {insightsVisible && (
                <div className="w-full bg-shadeStoneGray  p-4 rounded-b-lg">
                    <div className="flex-col justify-center">
                        <div className="flex items-start md:items-center justify-between">
                            <div className="flex flex-row items-center">
                                <ChartMixedOutline className="w-3 mr-4 md:mr-2" />
                                <div className="flex flex-col md:flex-row">
                                    <p className="font-bold text[14px]">Relationship insights</p>
                                    <p className="pl-1 text[14px]">(Visible only to me)</p>
                                </div>
                            </div>
                            <div className="flex items-start md:items-center">
                                <button type="button" onClick={() => setShowInsights(!showInsights)}>
                                    {showInsights
                                        ? (
                                            <div className="flex items-start md:items-center">
                                                <CloseIcon className="w-3 mr-2" />
                                                <p className="hidden md:block text[14px]">Hide</p>
                                            </div>
                                        )
                                        : (
                                            <div className="flex items-center">
                                                <EyeSolid className="w-3 mr-2" />
                                                <p className="hidden md:block text[14px]">Show</p>
                                            </div>
                                        )}
                                </button>
                            </div>
                        </div>
                        <div className={`${showInsights ? '' : 'hidden'}`}>
                            <RelationshipInsightsTable data={mockedContactData} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BriefHeader;
