import React from 'react';
import { Field } from 'formik';

import TextAreaInput from '@/components/bio/TextArea';
import FormError from '@/components/forms/FormError';

import { commPrefPlaceholder, meetingPrefPlaceholder } from '../mockedData';

function PreferencesEditor({
    brief = null, status,
}) {
    if (!brief) {
        return null;
    }

    return (
        <div>
            {(brief.meetingPreferences || brief.communicationPreferences)
                && (
                    <div className="bg-cirrusWhite rounded-lg p-6 mb-6 shadow-sm">
                        <p className="font-bold mb-6">Preferences</p>
                        <p className="font-bold mb-6">Meetings</p>
                        <div className="mb-6 ">
                            <Field
                                id="meetingPreferences"
                                name="meetingPreferences"
                                placeholder={meetingPrefPlaceholder}
                                component={TextAreaInput}
                                className="resize-none min-h-[508px] text-cleanSlate font-instrument text-base font-normal leading-7 px-4 py-3 w-full"
                            />
                            <FormError
                                error={status?.errors?.meetingPreferences as string[]}
                            />
                        </div>
                        <p className="font-bold mb-6">Communications</p>
                        <div className="mb-6">
                            <Field
                                id="communicationPreferences"
                                name="communicationPreferences"
                                placeholder={commPrefPlaceholder}
                                component={TextAreaInput}
                                className="resize-none min-h-[508px] text-cleanSlate font-instrument text-base font-normal leading-7 px-4 py-3 w-full"
                            />
                            <FormError
                                error={
                                    status?.errors?.communicationPreferences as string[]
                                }
                            />
                        </div>
                    </div>
                )}
        </div>
    );
}

export default PreferencesEditor;
