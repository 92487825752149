import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { handleFetchErrors, onCompletedHandler, onErrorHandler } from '@/services/apollo';
import Loader from '@/components/commons/loader';
import ToggleForm from '@/components/forms/toggle_form';
import { handleColumnSort, SortDirection } from '@/services/sorting';
import WarningCard from '@/components/commons/warning_card';
import SmartTable from '@/components/commons/table/SmartTable';
import { selectCircleIcon, selectGroupIcon, selectInteractionIcon } from '@/components/my-network/helpers';

import FETCH_CONTACTS from '../Actions';

export const DEFAULT_SORT_COLUMN = 'contactInfo';
export const DEFAULT_SORT_DIRECTION = SortDirection.ASC;

function RelationshipInsightsTable({ data }) {
    const [isError, setIsError] = useState(false);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [formError, setFormError] = useState('');

    // This should get interactions information, no contacts

    const {
        loading, refetch,
    } = useQuery(FETCH_CONTACTS, {
        variables: {
            sortColumn,
            sortDirection,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onError: onErrorHandler(setIsError),
        onCompleted: onCompletedHandler(setIsError, (_) => {
            // const contactsData = info?.user?.unifiedContacts;
            // setContactsList(contactsData);
        }),
    });

    const handleToggleVisibility = async () => {
        try {
            toast.success('This should change visibility on this contact');
            refetch();
        } catch (err) {
            handleFetchErrors(err);
            setFormError('An unexpected error occurred.  Please try again later.');
        }
    };

    const onSort = handleColumnSort(sortColumn, setSortColumn, sortDirection, setSortDirection);

    const interactionsColumn = {
        label: 'Interactions',
        id: 'interactions',
        onSort: () => onSort('interactions', DEFAULT_SORT_DIRECTION),
        render: (rowId, record) => {
            if (!record.interactions) {
                return <p>-</p>;
            }
            return (
                <div key={rowId} className="flex items-center min-w-[100px]">
                    <div className="flex flex-col items-start justify-center pl-3">
                        <p className="font-bold text-[18px] md:text-[16px]">{record.interactions}</p>
                    </div>
                </div>
            );
        },
    };

    const firstInteractionColumn = {
        label: 'First interaction',
        id: 'firstInteraction',
        render: (rowId, record) => {
            if (!record.firstInteraction) {
                return <p>-</p>;
            }
            return (
                <div key={rowId} className="text-cleanSlate flex items-start min-w-[150px] md:min-w-[200px]">
                    <div className="pr-2 pt-[2px]">
                        {selectInteractionIcon(record?.firstInteraction?.status, false)}
                    </div>
                    <div className="flex flex-col items-start justify-start">
                        <p className="font-bold">
                            {record?.firstInteraction?.status}
                            {record?.firstInteraction?.title}
                        </p>
                        <p className="text-[12px] md:text-[14px]">{record?.firstInteraction?.date}</p>
                    </div>
                </div>
            );
        },
    };

    const lastInteractionColumn = {
        label: 'Last interaction',
        id: 'recentMessagesReceivedCount',
        render: (rowId, record) => {
            if (!record.recentMessagesReceivedCount) {
                return <p>-</p>;
            }
            return (
                <div key={rowId} className="text-cleanSlate flex items-start min-w-[150px] md:min-w-[200px]">
                    <div className="pr-2 pt-[2px]">
                        {selectInteractionIcon(record?.recentMessagesReceivedCount?.status, false)}
                    </div>
                    <div className="flex flex-col items-start justify-start">
                        <p className="font-bold">
                            {record?.recentMessagesReceivedCount?.status}
                            {record?.recentMessagesReceivedCount?.title}
                        </p>
                        <p>{record?.recentMessagesReceivedCount?.date}</p>
                    </div>
                </div>
            );
        },
    };

    const circleColumn = {
        label: 'Relationship circle',
        id: 'circle',
        render: (rowId, record) => {
            if (record.effectiveCircle === null) {
                return <p>-</p>;
            }
            // TODO: That label seems not correct.
            return (
                <div key={rowId} className="text-cleanSlate flex items-center min-w-[150px]">
                    <div className="pr-[8px]">
                        {selectCircleIcon(record.effectiveCircle)}
                    </div>
                    <p>{`${record.effectiveCircle} contacts`}</p>
                </div>
            );
        },
    };

    const groupsColumn = {
        label: 'Groups',
        id: 'groups',
        render: (rowId, record) => {
            if (!record.primaryGroup) {
                return <p>-</p>;
            }
            const fieldName = `groups-${record.id}`;
            const initialValues = {};
            initialValues[fieldName] = record.enabled;
            return (
                <div key={rowId} className="flex items-center px-3 py-[2px] bg-shadeStoneGray rounded-lg w-min">
                    <div className="pr-1">
                        {selectGroupIcon(record.primaryGroup)}
                    </div>
                    <p className="font-medium">{record.primaryGroup}</p>
                </div>
            );
        },
    };

    const visibilityColumn = {
        label: 'Visibility',
        id: 'visibility',
        shrink: false,
        onSort: () => onSort('visibility', DEFAULT_SORT_DIRECTION),
        render: (rowId, record) => {
            const fieldName = `visible-${record.id}`;
            const initialValues = {};
            initialValues[fieldName] = record.visible;
            return (
                <div key={rowId} className="pr-2">
                    <Formik
                        onSubmit={() => { }}
                        initialValues={initialValues}
                    >
                        <div className="flex items-center justify-start">
                            <ToggleForm
                                record={record}
                                onChange={() => handleToggleVisibility()}
                                fieldName={fieldName}
                                propertyName="visible"
                            />
                            <p className="">{record.visible ? 'Visible' : 'Hidden'}</p>
                        </div>
                    </Formik>
                </div>
            );
        },
    };

    const columnGroup = [
        interactionsColumn,
        firstInteractionColumn,
        lastInteractionColumn,
        circleColumn,
        groupsColumn,
        visibilityColumn,
    ];

    const externalStyles = {
        title: 'hidden',
        table: 'bg-shadeStoneGray border-separate border-spacing-x-0 border-spacing-y-[8px]',
        wrapper: 'overflow-x-auto border-b border-lightGray bg-shadeStoneGray  pb-0',
        headerCell: 'pl-2 md:pl-4 bg-shadeStoneGray ',
        roundedRow: true,
        body: 'bg-gray-50 border border-shadeStoneGray rounded-lg',
    };

    return (
        <Loader isLoading={loading || !data} isError={isError}>
            {
                formError !== '' && (
                    <div className="w-full my-[20px]">
                        <WarningCard message={formError} />
                    </div>
                )
            }
            {(data && data.length)
                && (
                    <>
                        <div className="border-shadeStoneGray bg-cirrusWhite rounded-md mt-4">
                            {(data && data.length)
                                && (
                                    <SmartTable
                                        columns={columnGroup}
                                        data={data}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        paginationState={{ windowSize: 3, initialPage: 0 }}
                                        externalStyles={externalStyles}
                                    />
                                )}
                        </div>
                        <Tooltip id="tip" />
                    </>
                )}
        </Loader>
    );
}

export default RelationshipInsightsTable;
