import React from 'react';

interface Props {
    readonly headerText: string;
    readonly subText: string;
}

function InfoCard({
    headerText,
    subText,
}: Props) {
    return (
        <div className="w-auto p-4 h-[65px] bg-progressBarBlue flex items-center flex-col justify-center rounded-lg my-4 ml-4">
            <p className="text-cirrusWhite font-bold text-lg">{headerText}</p>
            <span className="text-cirrusWhite font-normal text-sm">{subText}</span>
        </div>
    );
}

export default InfoCard;
