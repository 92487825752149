import React, { useEffect } from 'react';
import Datepicker from 'tailwind-datepicker-react';

interface Props {
    readonly field: { // { name, value, onChange, onBlur }
        name: string;
        value: Date;
    };
    readonly show: boolean,
    readonly setShow: (show: boolean) => void,
    readonly options: any,
    readonly clearPicker: () => void,
}

function DatePicker({
    field, show, setShow, options, ...props
}: Props) {
    const value = field.value ? new Date(field.value) : null;

    useEffect(() => {
        const handleClear = () => {
            if (typeof props.clearPicker === 'function') {
                props.clearPicker();
            }
        };

        window.addEventListener('click', (e: Event) => {
            const target = e.target as HTMLElement;

            if (document.getElementById(options.inputIdProp)?.contains(target)) {
                return;
            }
            if ((target.tagName.toLowerCase() === 'button' && target.innerHTML === 'Clear')) {
                handleClear();
            }
        });
    }, [props, options.inputIdProp]);

    return (

        <Datepicker
            show={show}
            setShow={() => setShow(!show)}
            options={options}
            {...field}
            {...props}
            value={value}
        />
    );
}

export default DatePicker;
