import React, { useState } from 'react';
import { Field } from 'formik';

import TrashBin from '@/components/commons/icons/TrashBin';
import PlusRoundedSolid from '@/components/commons/icons/PlusRoundedSolid';
import FormError from '@/components/forms/FormError';
import Input from '@/components/bio/Input';
import TextAreaInput from '@/components/bio/TextArea';

import DatePickerEditor from './DatePicker';

function CareerEditor({
    brief = null,
    status,
    setWorkHistory,
}) {
    const [updatedWorkHistory, setUpdatedWorkHistory] = useState([...brief.workHistoryItems]);

    const updateWorkHistoryField = (i, field, value) => {
        const output = [...updatedWorkHistory];
        output[i][field] = value;
        setUpdatedWorkHistory(output);
        setWorkHistory(output);
    };

    const removeWorkHistory = (index, e) => {
        e.preventDefault();
        const output = [...updatedWorkHistory];
        output.splice(index, 1);
        setUpdatedWorkHistory(output);
        setWorkHistory(output);
    };

    const addNewWorkHistory = (e) => {
        e.preventDefault();
        const output = [...updatedWorkHistory];
        output.push({
            title: '',
            organization: '',
            description: '',
            startAt: null,
            endAt: null,
        });
        setUpdatedWorkHistory(output);
        setWorkHistory(output);
    };

    if (!brief) {
        return null;
    }

    return (
        <div className="bg-cirrusWhite rounded-lg p-6 mb-6 shadow-sm">
            <div className="flex items-center justify-between mb-2">
                <p className="font-bold mb-6">Career</p>
                <button
                    type="button"
                    data-tooltip-id="tip"
                    title="Add new"
                    aria-label="Add new"
                    className="flex items-center px-6 py-2 rounded-lg bg-cleanSlate"
                    onClick={(e) => addNewWorkHistory(e)}
                >
                    <PlusRoundedSolid className="w-4 h-4 mr-4 text-white" />
                    <p className="font-medium text-cirrusWhite">Add new</p>
                </button>
            </div>
            {updatedWorkHistory && updatedWorkHistory.map((el, i) => (
                <div key={`career-${updatedWorkHistory[i].id}`}>
                    <div className="flex flex-col shadow-sm border border-gray-200 bg-gray-50 rounded-lg mb-4 pt-2">
                        <div className="flex flex-col md:flex-row justify-between items-start px-1 md:px-8 py-2 ">
                            <div className="w-full pr-4 md:pr-2 pl-4 md:pl-0">
                                <p className="mt-2 md:mt-0 h-[40px] md:h-auto min-w-auto max-w-auto md:max-w-[33%] md:min-w-[33%] font-bold text-center md:text-start">Job Title</p>
                                <Field
                                    id="title"
                                    name="title"
                                    component={Input}
                                    value={updatedWorkHistory[i].title}
                                    onChange={(e) => updateWorkHistoryField(i, 'title', e.target.value)}
                                    placeholder="Engineering Director"
                                    className="resize-none text-cleanSlate font-instrument text-base font-normal leading-7 py-2 w-full"
                                    required
                                />
                                <FormError
                                    error={!updatedWorkHistory[i].title ? status?.errors?.workHistoryErrors[i] as string[] : ''}
                                />
                            </div>
                            <div className="w-full pl-4 md:pl-2 pr-4 md:pr-0 ">
                                <p className="mt-2 md:mt-0 h-[40px] md:h-auto min-w-auto max-w-auto md:max-w-[33%] md:min-w-[33%] font-bold text-center md:text-start">Organization</p>
                                <Field
                                    id="organization"
                                    name="organization"
                                    component={Input}
                                    value={updatedWorkHistory[i].organization}
                                    onChange={(e) => updateWorkHistoryField(i, 'organization', e.target.value)}
                                    placeholder="Amenitry Software"
                                    className="resize-none text-cleanSlate font-instrument text-base font-normal leading-7 py-2 w-full"
                                    required
                                />
                                <FormError
                                    error={!updatedWorkHistory[i].organization ? status?.errors?.workHistoryErrors[i] as string[] : ''}
                                />
                            </div>
                        </div>
                        <div className="px-5 md:px-8 pb-4">
                            <p className="mb-2 md:mb-4 mt-2 md:mt-0 h-[40px] md:h-auto min-w-auto max-w-auto md:max-w-[33%] md:min-w-[33%] font-bold text-center md:text-start">Description of role</p>
                            <Field
                                id="description"
                                name="description"
                                placeholder="In January 2023 I joined Amity Software as its Engineering Director. My emphasis on building diverse and equitable teams was a compelling|"
                                component={TextAreaInput}
                                value={updatedWorkHistory[i].description}
                                onChange={(e) => updateWorkHistoryField(i, 'description', e.target.value)}
                                className="resize-none min-h-[120px] text-cleanSlate font-instrument text-base font-normal leading-7 px-4 py-2 w-full"
                            />
                        </div>
                        <div className="flex flex-col md:flex-row items-center md:items-end justify-between">
                            <div className="flex-grow">
                                <DatePickerEditor
                                    updateWorkHistoryField={updateWorkHistoryField}
                                    index={i}
                                    updatedWorkHistory={el}
                                    status={status}
                                />
                            </div>
                            <button
                                type="button"
                                data-tooltip-id="tip"
                                title="Remove item"
                                aria-label="Remove item"
                                className="flex items-center mx-auto md:mx-0 md:mr-8 mb-7"
                                onClick={(e) => removeWorkHistory(i, e)}
                            >
                                <TrashBin className="w-4 h-4 mr-4 text-solidRed" />
                                <p className="text-solidRed">Remove Item</p>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CareerEditor;
