export const mockedContactData = [
    {
        interactions: '3,448',
        firstInteraction: {
            status: 'Intro',
            title: ': Me!',
            date: 'Thurs Jun 12 2008 at 12:03pm',
        },
        recentMessagesReceivedCount: {
            status: 'Re',
            title: ': Meeting sync',
            date: 'Monday Feb 12 2024 at 2:34pm',
        },
        chosenCircle: 'Close',
        primaryGroup: 'Professional',
        visible: true,
        active: true,
        id: 1,
    },
];

export const bioPlaceholder = 'Ryan Cornell-Hayes is the senior engineering manager at Amity Software. For sixteen years, they have worked on successful software teams, emphasizing diversity and equality both in product and team dynamics. In their spare time, Ryan plays pickleball, collects vintage bottlecaps, and mentors underprivileged youths.';

export const meetingPrefPlaceholder = `In your own words, tell us about your communications preferences. Here’s an example from Mat, our founder:

•   I’m an email guy. I commit to reading every email from someone I know within 1 working day. Even if I haven’t responded, I’ve read your email.
•   If it’s urgent, send me a text.
•   I love Slack for collaborating. If you think I can help, @ me. I’ll always prefer a huddle.
•   Do not ever leave me a voicemail. They get deleted. If you get my voicemail and need to find me right now, text me.
•   If you’re a colleague, feel free to swing by. Call ahead in case I’m OOO.`;

export const commPrefPlaceholder = `In your own words, tell us about your communications preferences. Here’s an example from Mat, our founder:

•   I’m an email guy. I commit to reading every email from someone I know within 1 working day. Even if I haven’t responded, I’ve read your email.
•   If it’s urgent, send me a text.
•   I love Slack for collaborating. If you think I can help, @ me. I’ll always prefer a huddle.
•   Do not ever leave me a voicemail. They get deleted. If you get my voicemail and need to find me right now, text me.
•   If you’re a colleague, feel free to swing by. Call ahead in case I’m OOO.`;
